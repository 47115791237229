import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { RichText } from "../api/contentful"
import ArticleGrid from "../components/article-grid"
import { Section, Pagehead, PageTitle, Breadcrumbs } from "../components/shared"

const NarrowArticleGrid = styled(ArticleGrid)`
  @media only screen and (min-width: 800px) {
    max-width: 50em;
    margin: 0 auto;
  }
`

const Magazin = ({ data }) => {
  const pageData = data.contentfulMagazinePage

  const articles = data.allContentfulArticle.edges
    .map(edge => edge.node)
    .sort((a, b) => -a.publishDate.localeCompare(b.publishDate))

  const projects = data.allContentfulProject.edges.map(edge => edge.node)
  const project = projects[0]

  return (
    <Layout>
      <SEO title={pageData.title} description={pageData.metaDescription} />
      <Pagehead bottomMargin>
        <PageTitle>
          <RichText node={pageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <Breadcrumbs nodes={[{ title: "Magazin" }]} />
      </Pagehead>
      <Section>
        <NarrowArticleGrid articles={articles} project={project} />
      </Section>
      <Contact />
    </Layout>
  )
}

export default Magazin

export const query = graphql`
  query MagazinQuery {
    contentfulMagazinePage {
      title
      metaDescription
      pageTitle {
        json
      }
    }

    allContentfulArticle {
      edges {
        node {
          title
          slug
          publishDate
          coverImage {
            fluid(quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          text {
            json
          }
        }
      }
    }

    allContentfulProject {
      edges {
        node {
          title
          slug
          description {
            json
          }
          timberVolume
          photos {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`
